// @ts-nocheck
import React from 'react';

const CustomerDepositsDeleteAlert = React.lazy(
  () => import('@/containers/Alerts/CustomerDeposits/CustomerDepositsDeleteAlert'),
);

/**
 * custoemr deposits alerts.
 */
export default [
  {
    name: 'customer-deposits-delete',
    component: CustomerDepositsDeleteAlert,
  },
];
